import axios from "../signin/axios";
import qs from "qs";

function HttpError(status,error){
    this.response = {
        "data":error
    };
    this.status = status;
}

const instance={};

export const executeRequestApi = async(payload)=>{
    let requesturl = payload.url;
    let requestmethod = payload.method;
    let requestheaders = payload.headers;
    let querystring = payload.query;
    if(requestmethod=="GET"){
        let fullurl = requesturl+"?"+querystring;
        let res = await fetch(fullurl, {
            "method": "GET",
            headers: requestheaders
        })
        if(!res.ok){
            let err = await res.json();
            throw new HttpError(res.status,err);
        }
        let data = await res.json();
        return {
            "status": res.status,
            data: data
        }
    }else if(requestmethod=="POST"){
        let fullurl = requesturl+"?"+querystring;
        if(payload.type=="form-data"){
          let formdata = new FormData();
          for(let i=0; i< payload.formData.length; i++){
            formdata.append(payload.formData[i].key, payload.formData[i].value);
          }
          let res = await fetch(fullurl,{
                                            "method": "POST",
                                            "headers": requestheaders,
                                            body: formdata
                                })
            if (!res.ok) {
                let err = await res.json();
                throw new HttpError(res.status,err);
            }
            let data = await res.json();
            return {
                "status": res.status,
                data: data
            }
        }else{
            let res = await fetch(fullurl, 
                                    {
                                        "method": "POST",
                                        "headers": requestheaders,
                                        body: payload.rawBody
                                    }
                                 )
            if(!res.ok){
                let err = await res.json();
                throw new HttpError(res.status, err);
            }
            let data = await res.json();
            return {
                "status": res.status,
                data: data
            }
        }

    }else if(requestmethod=="PUT"){
        let fullurl = requesturl+"?"+querystring;
        if(payload.type=="form-data"){
          let formdata = new FormData();
          for(let i=0; i< payload.formData.length; i++){
            formdata.append(payload.formData[i].key, payload.formData[i].value);
          }
          let res = await fetch(fullurl,{
                                            "method": "PUT",
                                            "headers": requestheaders,
                                            body: formdata
                                })
            if (!res.ok) {
                let err = await res.json();
                throw new HttpError(res.status,err);
            }
            let data = await res.json();
            return {
                "status": res.status,
                data: data
            }
        }else{
            let res = await fetch(fullurl, 
                                    {
                                        "method": "PUT",
                                        "headers": requestheaders,
                                        body: payload.rawBody
                                    }
                                 )
            if(!res.ok){
                let err = await res.json();
                throw new HttpError(res.status, err);
            }
            let data = await res.json();
            return {
                "status": res.status,
                data: data
            }
        }
    }else if(requestmethod=="DELETE"){
        let fullurl = requesturl+"?"+querystring;
        let res = await fetch(fullurl, {
            "method": "DELETE",
            headers: requestheaders
        })
        if(!res.ok){
            let err = await res.json();
            throw new HttpError(res.status,err);
        }
        let data = await res.json();
        return {
            "status": res.status,
            data: data
        }
    }
    
}
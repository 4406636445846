import { useEffect, useState } from "react";
import { 
    getOutputSchema, 
    getStageError
} from "../../utils";
import "./BlockOutput.css";
import DisplayObject from "../../apps/DisplayOutputs/DisplayObject";

const BlockOutput = ({stageindex})=>{

    const [isError, setIsError] = useState(false);

    useEffect(()=>{
        if(getStageError(stageindex)!=undefined){
            setIsError(true)
        }
    },[stageindex])

    return (
        <>
            <div className="stage-params-header">
                <div className={isError?"stage-params-header-option":"stage-params-header-option active"} onClick={()=>{setIsError(false)}}>
                    Output
                </div>
                <div className={isError?"stage-params-header-option active":"stage-params-header-option"} onClick={()=>{setIsError(true)}}>
                    Error
                </div>
            </div>
            <div>
                {isError?
                    <>
                        <pre>
                            {getStageError(stageindex)!==undefined&&
                                JSON.stringify(getStageError(stageindex), null, 2)
                            }
                        </pre>
                    </>:
                    <>
                        <pre>
                            {getOutputSchema(stageindex).length>0&&
                                <DisplayObject
                                    schema={getOutputSchema(stageindex)}
                                    spacer={""}
                                    location={""}
                                    stageindex={stageindex}
                                >
                                </DisplayObject>
                            }
                        </pre>            
                    </>
                }
                
                
                                                    
            </div>
        </>
    )

}


export default BlockOutput;
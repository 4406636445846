import "./DashboardRouter.css";
import { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation , Link} from "react-router-dom";
import { MenuBar } from "./Menubar";
import InputDesigner from './apps/InputDesigner';
import Schemalist from './apps/Schemas/Schemalist';
import Applist from './apps/Applist';
import CreateMotors from './apps/Motors/CreateMotors';
import Pipelineloglist from './apps/Motors/Pipelineloglist';
import Pipeline from './apps/Motors/Pipeline';
import Interfaces from './apps/interfaces/interface';
import { Button, IconButton } from "@mui/material";
import { useDispatch , useSelector} from "react-redux";
import { selectUsername, signout } from "./Services/signin/signin.slice";
import Dashboard from "./Dashboard";
import Toiler from "./Images/logo1.png";
import LinearProgress from "@mui/material/LinearProgress";
import { loadingSelector } from "./Services/loader/loader.slice";
import FileSystem from "./apps/filesystem/FileSystem";
import MlModels from "./apps/mlmodels/mlmodels";
import Pipelinelogview from "./apps/Motors/Pipelinelogview";
import Templatelist from "./apps/interfaces/templates/Templatelist";
import TextTemplate from "./apps/interfaces/templates/TextTemplate";
import AppDesigner from "./apps/appdesigner/AppDesigner";
import Preview from "./apps/appdesigner/preview/Preview";
import Websitelist from "./apps/websites/Websitelist";
import AndroidApp from "./apps/androidapps/Androidapp";
import Iosapps from "./apps/iosapps/Iosapps";
import WebsiteDetails from "./apps/websites/WebsiteDetails";
import Androidlist from "./apps/androidapps/Androidlist";
import Workspaces from "./apps/workspaces/Workspaces";
import Permanentredirect from "./Permanentredirect";
import ClientSettings from "./apps/clientsettings/ClientSettings";
import { Domain } from "@mui/icons-material";
import Collaborators from "./apps/clientsettings/Collaborators";
import Domains from "./apps/clientsettings/Domains";
import WorkspaceDetails from "./apps/workspaces/WorkspaceDetail";
import CollaboratorDetail from "./apps/clientsettings/CollaboratorDetails";
import TestComponent from "./TestComponent";
import { makeSchemaUserInactive, selectschemaactive } from "./Services/schemas/schema.slice";
import Manifest from "./apps/manifest/Manifest";
import Readme from "./apps/readme/Readme";
import ReadmeContainer from "./apps/readme/ReadmeContainer";
import ManifestDetails from "./apps/manifest/ManifestDetails";
import MarketplaceGrid from "./apps/marketplace/MarketplaceGrid";
import MarketplaceGridPage from "./apps/marketplace/MarketplaceGridPage";

const DashboardRouter = ()=>{

    const dispatch = useDispatch();
    
    const navigate = useNavigate();

    const [showuser, setShowuser] = useState(false);

    const emailid = useSelector(selectUsername);

    const signoutaction = ()=>{
        dispatch(signout())
        navigate("/login");
    }

    const location = useLocation();


    return(
        <div className="dashboard-container">
            <div className='app-header-menu'>
                <div>
                    <Link to="/">
                        <img src={Toiler}></img>
                    </Link>
                </div>
                <div style={{display: "flex"}}>
                    {
                      showuser?
                      <>
                      
                      </>:
                      <>
                        <div>
                            <Button onClick={()=>{signoutaction()}} style={{color:"#3A07CD"}}>Logout</Button>
                        </div>
                      </>  
                    }
                    <div style={{position: "relative"}}>
                        <IconButton onClick={()=>{setShowuser(!showuser)}}>
                            <span className="material-symbols-outlined">
                                person
                            </span>
                        </IconButton>
                        {showuser&&
                            <div style={{position: "absolute", right: "0px", zIndex: 101, backgroundColor: "#fff"}}>
                                <div style={{
                                                display: "flex", 
                                                justifyContent: "flex-end"
                                            }}>
                                    <IconButton onClick={()=>{setShowuser(false)}}>
                                        <span className="material-symbols-outlined">
                                            close
                                        </span>
                                    </IconButton>
                                </div>
                                <div style={{padding: "10px"}}>
                                    {emailid}
                                </div>
                                <div>
                                    <Button onClick={()=>{signoutaction()}} style={{color:"#3A07CD"}}>Logout</Button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/* {loading > 0 && <LinearProgress />} */}
            <div className="app-main-container">
                <div className='menu'>
                    <MenuBar></MenuBar>
                </div>
                <div className="container">
                    <Routes>
                        <Route path="/:workspace/apps" element={<Applist></Applist>}></Route>
                        <Route path="/:workspace/schemas/:appid" element={<Schemalist></Schemalist>}></Route>
                        <Route path="/:workspace/schemas/:appid/:source/:sourceid" element={<ReadmeContainer></ReadmeContainer>}></Route>
                        <Route path="/:workspace/schemas/:appid/details/:id" element={<InputDesigner></InputDesigner>}></Route>
                        <Route path="/:workspace/schemas/:appid/details/:id/docs/:source/:sourceid" element={<ReadmeContainer></ReadmeContainer>}></Route>
                        <Route path="/:workspace/schemas/:appid/details/:id/:pipelineid/:source" element={<Pipeline></Pipeline>}></Route>
                        <Route path="/:workspace/appdesginer/:type/:templateid/details/:tempsource/:pipelineid/:source" element={<Pipeline></Pipeline>}></Route>
                        <Route path="/:workspace/schemas/:appid/create" element={<InputDesigner></InputDesigner>}></Route>
                        <Route path="/:workspace/pipelinelogs/:appid/:schemaid/:pipelineid/:status" element={<Pipelineloglist></Pipelineloglist>}></Route>
                        <Route path="/:workspace/pipelinelogs/:appid/:schemaid/:pipelineid/:status/:logid" element={<Pipelinelogview></Pipelinelogview>}></Route>
                        <Route path="/settings" element={<ClientSettings></ClientSettings>}></Route>
                        <Route path="/settings/domains" element={<Domains></Domains>}></Route>
                        <Route path="/settings/collaborators" element={<Collaborators></Collaborators>}></Route>
                        <Route path="/settings/collaborators/details" element={<CollaboratorDetail></CollaboratorDetail>}></Route>
                        <Route path="/settings/collaborators/details/:id" element={<CollaboratorDetail></CollaboratorDetail>}></Route>
                        <Route path="/:workspace/interfaces" element={<Interfaces></Interfaces>}></Route>
                        <Route path="/:workspace/templates" element={<Templatelist></Templatelist>}></Route>
                        <Route path="/:workspace/templates/text/:id" element={<TextTemplate></TextTemplate>}></Route>
                        <Route path="/:workspace/appdesigner/:type/:__source/:__id/*" element={<AppDesigner></AppDesigner>}></Route>
                        <Route path="/:workspace/apptemplates/:source/:id" element={<Preview></Preview>}></Route>
                        <Route path="/:workspace/websites/" element={<Websitelist></Websitelist>}></Route>
                        <Route path="/:workspace/website/details/:id" element={<WebsiteDetails></WebsiteDetails>}></Route>
                        <Route path="/:workspace/androidapps/" element={<Androidlist></Androidlist>}></Route>
                        <Route path="/:workspace/apps/details/:id" element={<AndroidApp></AndroidApp>}></Route>
                        <Route path="/:workspace/iosapps/" element={<Iosapps></Iosapps>}></Route>
                        <Route path="/:workspace/mlmodels" element={<MlModels></MlModels>}></Route>
                        <Route path="/:workspace/filesystem" element={<FileSystem></FileSystem>}></Route>
                        <Route path="/:workspace" element={<Dashboard></Dashboard>}></Route>
                        <Route path="/:workspace/manifest/:source/:sourceid" element={<Manifest></Manifest>}></Route>
                        <Route path="/:workspace/savedmanifest/:source/:sourceid/:id" element={<ManifestDetails></ManifestDetails>}></Route>
                        <Route path="/:workspace/manifest/:msource/:msourceid/:id/:source/:sourceid" element={<ReadmeContainer></ReadmeContainer>}></Route>
                        <Route path="/:workspace/marketplace/" element={<MarketplaceGridPage></MarketplaceGridPage>}></Route>
                        <Route path="/workspaces" element={<Workspaces></Workspaces>}></Route>
                        <Route path="/:workspace/readme/:source/:sourceid" element={<Readme></Readme>}></Route>
                        <Route path="/workspaces/:workspace" element={<WorkspaceDetails></WorkspaceDetails>}></Route>
                        <Route path="/workspaces/:workspace/:source/:sourceid" element={<ReadmeContainer></ReadmeContainer>}></Route>
                        <Route path="/" element={<Permanentredirect></Permanentredirect>}></Route>
                        <Route path="/testcomp/:id" element={<TestComponent></TestComponent>}></Route>
                    </Routes>
                </div>
            </div>
        </div>
    )
}


export default DashboardRouter;
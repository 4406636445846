import { useParams } from "react-router-dom";
import "./Menubar.css";
import { useSelector } from "react-redux";
import { selectClientId, selectDefaultWorkspace } from "./Services/signin/signin.slice";
import { Link } from "react-router-dom";
export const MenuBar = ()=>{

    const defaultworkspace = useSelector(selectDefaultWorkspace);

    return (
        <div className="menubar-list">
            {/* <a href="/" style={{textDecoration: "none"}}>
                <div className="menubar-item">
                    Dashboard
                </div>
            </a> */}
            <Link to="/workspaces" style={{textDecoration: "none"}}>
                <div className="menubar-item">
                    Workspaces
                </div>
            </Link>
            <Link to={"/"+defaultworkspace+"/apps"} style={{textDecoration: "none"}}>
                <div className="menubar-item">
                    Apps
                </div>
            </Link>
            {/* Interfaces to be of type -> webapp-> android app -> ios app -> websdk-> android sdk -> ios sdk -> notification templates(email , sms, push , etc...) */}
            <Link to={"/"+defaultworkspace+"/interfaces"} style={{textDecoration: "none"}}>
                <div className="menubar-item">
                    Interfaces
                </div>
            </Link>
            <Link to={"/"+defaultworkspace+"/filesystem"} style={{textDecoration: "none"}}>
                <div className="menubar-item">
                    FileSystem
                </div>
            </Link>
            <Link to={"/"+defaultworkspace+"/mlmodels"} style={{textDecoration: "none"}}>
                <div className="menubar-item">
                    ML Models
                </div>
            </Link>
            <Link to={"/"+defaultworkspace+"/marketplace"} style={{textDecoration: "none"}}>
                <div className="menubar-item">
                    Marketplace
                </div>
            </Link>
            <Link to="/settings" style={{textDecoration: "none"}}>
                <div className="menubar-item">
                    Settings
                </div>
            </Link>
           
        </div>
    )
}
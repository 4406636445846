import {deleteM, get, postform, postjson, putjson} from "../signin/axios";
import { baseURL } from "../../env";
import { executeStageAction } from "./server/upipeline";
import {StageErrorWrapper} from "./server/server-apps/errors";

export const testActionApi = async(payload, dispatch, getState)=>{
    if(payload.source=="apps"||payload.source=="installmanifest"){
        let res = await postjson(baseURL+"actions/test", payload.payload);
        return res;
    }
    if(payload.source=="apptemplates"){
        // let delineatedPipeline = {...payload.payload.delineatedPipeline};
        let effects = await executeStageAction(
                                                payload.payload.clientid,
                                                payload.payload.id,
                                                payload.payload.pipeline,
                                                payload.payload.mappingMap,
                                                payload.payload.index,
                                                payload.payload.key,
                                                dispatch,
                                                getState
                                              )
        return effects;
    }
}

export const reorderpipelines = async(payload)=>{
    let res = await putjson(baseURL+"actions/reorder/"+payload.id, {"reorders": payload.reorders});
    return res;
}

export const testPipelineApi = async(payload)=>{
    let res = await postjson(baseURL+"actions/pipeline/test", payload);
    return res;
}

export const runPipelineApi = async(payload)=>{
    let res = await postjson(baseURL+"actions/pipeline/run", payload);
    return res;
}

export const createPipelineApi = async(payload)=>{
    let res = await postjson(baseURL+ "pipelines/", {name: "Untitled", schemaid: payload.schemaid, source: payload.source, workspace: payload.workspace});
    return res;
}

export const updatePipelineApi = async(payload)=>{
    let res =  await putjson(baseURL+ "pipelines/"+payload.id, 
                                {
                                    name: payload.name, 
                                    pipeline: payload.pipeline, 
                                    mappingMap: payload.mappingMap, 
                                    delineatedpipeline:payload.delineatedpipeline,
                                    source: payload.source,
                                    onload: payload.onload,
                                    expose: payload.expose,
                                    reactive: payload.reactive
                                });
    return res;
}

export const updatePipelineApiF = async(payload)=>{
    let res = await putjson(baseURL+ "pipelines/f/"+payload.id,
                                {
                                    pipeline: payload.pipeline,
                                    mappingMap: payload.mappingMap,
                                    source: payload.source
                                }
                           )
    return res;
}

export const getPipelinebySchemaApi = async(payload)=>{
    let res = await get(baseURL+"pipelinesbyschema/"+payload.schemaid);
    return res;
}

export const getPiplelineByIdApi = async(payload)=>{
    let res = await get(baseURL+"pipelines/"+payload.id);
    return res;
}

export const deletePipelineByIdApi = async(payload)=>{
    let res = await deleteM(baseURL+"pipelines/"+payload.id);
    return res;
}

export const getPipelineTriggersBySchemaApi = async(payload)=>{
    let res = await get(baseURL+"pipelinesbyschema/manual/"+payload);
    return res;
}

export const uploadFileApi = async(payload)=>{
    let formData = new FormData();
    formData.append("file", payload.file);
    let res = await postform(baseURL+"actions/upload/", formData);
    return res;
}

export const registerEventApi  = async (event)=>{
    let res = await postjson(baseURL+"events/", event);
    return res;
}

export const getEventsApi = async (schemaid)=>{
    let res = await get(baseURL+"events/"+schemaid);
    return res;
}

export const deleteEventApi = async (eventid)=>{
    let res = await deleteM(baseURL+"events/"+eventid);
    return res;
}

export const testResponseApi = async(payload)=>{
    let res = await postjson(baseURL+"response/test",payload);
    return res;
}

export const getLoopsApi = async(payload)=>{
    let res = await get(baseURL+"events/loops/");
    return res;
}

export const getSchemaEventsApi = async(payload)=>{
    let res = await get(baseURL+"events/schema/"+payload);
    return res;
}

export const deployPipelineApi = async(payload)=>{
    let res = await get(baseURL+"pipeline/deploy/"+payload);
    return res;
}

export const activatePipelineApi = async(payload)=>{
    let res = await get(baseURL+"pipeline/activate/"+payload);
    return res;
}

export const pausePipelineApi = async(payload)=>{
    let res = await get(baseURL+"pipeline/pause/"+payload);
    return res;
}

export const getInputStageApi = async(payload)=>{
    let res = await get(baseURL+ "run/inputstage/"+payload);
    return res;
}

export const getNextSchemaApi = async(payload)=>{
    let res = await postjson(baseURL+"snapshotsschemas/next/", payload);
    return res;
}
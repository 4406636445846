import { useDispatch, useSelector } from "react-redux";
import ElementContainer from "../ElementContainer";
import { 
        jsoncompare, 
        selectInnerHtml, 
        selectElementStyles, 
        selectProps, 
        selectActivePosition, 
        selectElementClasses, 
        selectFocusPosition, 
        setActivePosition,
        setFocusPosition,
        selectFocusKey,
        selectActiveKey,
        selectActivePath,
        selectFocusPath,
        selectActivetemplateid
    } from "../../../Services/apptemplates/apptemplates.slice";
import { getInteracionRunner } from "../utils";
import InputContainers from "./InputContainers";
import { useLocation, useParams } from "react-router-dom";
import RouterContainer from "./RouterContainer";
import { useCallback, useEffect, useRef, useState } from "react";
import "./HtmlNativeElement.css";
import * as _ from "lodash";
import VideoElement from "./VideoElement";


const HtmlNativeElements = ({
                              element,
                              elmkey,
                              indexarr,
                              position,
                              type,
                              childs,
                              parentkey,
                              parentindexarr,
                              parentposition,
                              templateinteractions,
                              path,
                              indesign                              
                            })=>{

    const activetemplateid = useSelector(selectActivetemplateid);

    const [elmActive, setElmActive] = useState(false);

    const [elmFocus, setElmFocus] = useState(false);

    const routeParams = useParams();
    
    const innerhtml = useSelector((state)=>
                                    selectInnerHtml(
                                                        state,
                                                        {schema: element.innerhtml, schemamapping: element.innerhtmlmapping, key:elmkey},
                                                        routeParams,
                                                        elmkey,
                                                        position
                                                    ), jsoncompare);
    
    

    const dispatch = useDispatch();


    const clsString = useSelector(
                                    (state)=>{
                                                return selectElementClasses(
                                                                                state, 
                                                                                element.scusers, 
                                                                                element.scsystems, 
                                                                                elmkey, 
                                                                                position
                                                                            )
                                            }
                                )

    const props = useSelector((state)=>{return selectProps(
                                                            state, 
                                                            {schema: element.props, schemamapping: element.propsmapping},
                                                            elmkey,
                                                            position
                                                            )}, jsoncompare )
    
    const setElementInFocusAction = (position, key)=>{
        dispatch(setFocusPosition({
                                    position: position,
                                    key: key,
                                    focussource: "canvas"
                                  }))
    }

    const setElementActiveAction = (position, key)=>{
        dispatch(setActivePosition({
                                    position: position,
                                    key: key,
                                    focussource: "canvas"
                                   }));
    }

    const [ref, setRef] = useState(null);

    const elmref = useCallback((node)=>{
        setRef(node)
    },[])

    const fclick = (event)=>{
        if(event.target==ref){
            if(elmkey!=null&&elmkey!=""){
                setElementActiveAction(position, elmkey)
            }
        }
    }

    const fmouseleave = (event)=>{
        if(event.target==ref){
            if(elmkey!=null&&elmkey!=""){
                setElementInFocusAction([],elmkey)
            }
        }
    }

    const fmouseenter = (event)=>{
        if(event.target==ref){
            if(elmkey!=null&&elmkey!=""){
                setElementInFocusAction(position,elmkey)
            }
        }
    }

    useEffect(()=>{
        if(ref!=null&&elmkey!=undefined){
            ref.addEventListener("click", fclick);
            ref.addEventListener("mouseenter", fmouseenter);
            ref.addEventListener("mouseleave", fmouseleave);
            return ()=>{
                ref.removeEventListener("click", fclick);
                ref.removeEventListener("mouseenter", fmouseenter);
                ref.removeEventListener("mouseleave", fmouseleave)        
            }
        }
    },[ref, activetemplateid, elmkey])
    
    const getInteractions = ()=>{
        let interactions = {};
        const mouseenter = false;
        const mouseleave = false;
        let elmclicked = false;
        for(let i=0; i< element.interactions.length; i++){
            if(
                element.interactions[i].name!=""&&
                element.interactions[i].name!=undefined&&
                element.interactions[i].pipeline!=""&&
                element.interactions[i].pipeline!=undefined
            ){
                if(interactions[element.interactions[i].name]!=undefined){
                    interactions[element.interactions[i].name].interactions.push(
                        {
                            key: elmkey,
                            pipelineid: element.interactions[i].pipeline,
                            schemamapping: element.interactions[i].schemamapping,
                            position: position
                         }
                    )
                }else{
                    interactions[element.interactions[i].name] = {
                                                                    name: element.interactions[i].name,
                                                                    interactions: []
                                                                  };
                    interactions[element.interactions[i].name].interactions.push(
                        {
                            key: elmkey,
                            pipelineid: element.interactions[i].pipeline,
                            schemamapping: element.interactions[i].schemamapping,
                            position: position
                         }
                    )
                }
            }
                        
        }
        if(templateinteractions){
            for(let i=0; i < templateinteractions.length; i++){
                if(
                    templateinteractions[i].name!=""&&
                    templateinteractions[i].name!=undefined&&
                    templateinteractions[i].pipeline!=""&&
                    templateinteractions[i].pipeline!=undefined
                ){
                    if(templateinteractions[i].type=="browser"){
                        if(interactions[templateinteractions[i].name]!=undefined){
                            interactions[templateinteractions[i].name].push(
                                {
                                    key: parentkey,
                                    pipelineid: templateinteractions[i].pipeline,
                                    schemamapping: templateinteractions[i].schemamapping,
                                    position: parentposition
                                 }
                            )
                        }else{
                            interactions[templateinteractions[i].name] = {
                                                                            name: templateinteractions[i].name,
                                                                            interactions: []
                                                                         };
                            interactions[templateinteractions[i].name].push(
                                {
                                    key: parentkey,
                                    pipelineid: templateinteractions[i].pipeline,
                                    schemamapping: templateinteractions[i].schemamapping,
                                    position: parentposition
                                 }
                            )
                        }
                    }
                }
            }
        }
        let loadedinteractions = Object.keys(interactions);
        let runnableinteractions= {};
        for(let i=0; i< loadedinteractions.length; i++){
            let eventinteractions = interactions[loadedinteractions[i]].interactions;
            runnableinteractions[interactions[loadedinteractions[i]].name] = (e)=>{
                let interactionrunner = getInteracionRunner(eventinteractions, dispatch, e);
                interactionrunner();
            }
        }
        return runnableinteractions;
    }

    const [elementLoaded, setElementLoaded] = useState(false);

    const getExtraProps = ()=>{
        let extraprops = {};
        if(element.extraprops.length>0){
            for(let i=0; i<element.extraprops.length; i++){
                extraprops[element.extraprops[i].key] =element.extraprops[i].value 
            }
        }
        return extraprops;
    }

    const elementActive = useSelector(selectActivePosition);

    const activekey = useSelector(selectActiveKey);

    const activepath = useSelector(selectActivePath);

    useEffect(()=>{
        if(
            JSON.stringify(indexarr)==JSON.stringify(elementActive)&&
            activekey==elmkey
          ){
            setElmActive(true);
        }else{
            setElmActive(false);
        }
    },[elementActive])

    const elementFocus = useSelector(selectFocusPosition);

    const focuskey = useSelector(selectFocusKey);

    useEffect(()=>{
        if(
            JSON.stringify(indexarr)==JSON.stringify(elementFocus)&&
            focuskey==elmkey
        ){
            setElmFocus(true);
        }else{
            setElmFocus(false);
        }
    },[elementFocus])

    return (
        <>
            {element.tag=="div"&&
                <div 
                    {...getInteractions()}
                    {...getExtraProps()}
                    {...props}
                    ref={elmref}
                    className={
                        (elmActive||elmFocus)?
                        "element-active "+clsString:
                        clsString
                    }
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                        <ElementContainer
                                            elmkey={elmkey}
                                            element={elm}
                                            indexarr={[...indexarr, index]}
                                            position={[...position, index]}
                                            type={type}
                                            parentkey={parentkey}
                                            parentindexarr={parentindexarr}
                                            parentposition={parentposition}
                                            path={path}
                                            indesign={indesign}                                            
                                        >
                                        </ElementContainer>
                                    )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </div>
            }
            {element.tag=="p"&&
                <p 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                              }
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(
                                        <ElementContainer
                                            elmkey = {elmkey}
                                            element = {elm}
                                            indexarr = {[...indexarr, index]}
                                            position = {[...position, index]}
                                            type={type}
                                            parentkey={parentkey}
                                            parentindexarr={parentindexarr}
                                            parentposition={parentposition}
                                            path={path}
                                            indesign={indesign}
                                        >
                                        </ElementContainer>
                                    )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </p>
            }
            {element.tag=="span"&&
                <span 
                    {...getInteractions()}
                    {...getExtraProps()}
                    {...props}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                              }
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                        path={path}
                                        indesign={indesign}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </span>
            }
            {element.tag=="a"&&
                <a 
                    {...getInteractions()}
                    {...getExtraProps()}
                    {...props}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                              }
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                        path={path}
                                        indesign={indesign}
                                    >
                                    </ElementContainer>
                                )
                                
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}   
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </a>
            }
            {element.tag=="img"&&
                <img 
                    {...getInteractions()}
                    {...getExtraProps()}
                    {...props}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                             }

                />
            }
            {element.tag=="input"&&
                <InputContainers
                    elmkey={elmkey}
                    element={element}
                    indexarr={indexarr}
                    position = {position}
                    clsName={clsString}
                    interactions={getInteractions()}
                    ref={elmref}
                    elmActive={elmActive}
                    elmFocus={elmFocus}
                    
                >
                </InputContainers>
            }
            {element.tag=="select"&&
                <InputContainers
                    elmkey={elmkey}
                    element={element}
                    indexarr={indexarr}
                    position={position}
                    clsName={clsString}
                    interactions={getInteractions()}
                    ref={elmref}
                    elmActive={elmActive}
                    elmFocus={elmFocus}
                    
                >
                </InputContainers>
            }
            {element.tag=="option"&&
                <option 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                              }
                >
                    {innerhtml}
                </option>
            }
            {element.tag=="form"&&
                <form 
                    {...getInteractions()}
                    {...props}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                            }
                    {...getExtraProps()}
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                        path={path}
                                        indesign={indesign}
                                        
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </form>
            }
            {element.tag=="button"&&
                <button 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                            }
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                        path={path}
                                        indesign={indesign}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}   
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </button>
            }
            {element.tag=="table"&&
                <table 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                            }
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                        path={path}
                                        indesign={indesign}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </table>
            }
            {element.tag=="thead"&&
                <thead 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                            }
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                        path={path}
                                        indesign={indesign}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </thead>
            }
            {element.tag=="tbody"&&
                <tbody 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                            }
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                        path={path}
                                        indesign={indesign}
                                    >
                                    </ElementContainer>
                                )
                                
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </tbody>
            }
            {element.tag=="tr"&&
                <tr 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                            }
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                        type={type}
                                        path={path}
                                        indesign={indesign}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </tr>
            }
            {element.tag=="td"&&
                <td 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                            }
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                        type={type}
                                        path={path}
                                        indesign={indesign}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </td>
            }
            {element.tag=="svg"&&
                <svg 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                            }
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                        path={path}
                                        indesign={indesign}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </svg>
            }
            {element.tag=="path"&&
                <>
                    <path
                        {...getInteractions()}
                        {...props}
                        {...getExtraProps()}

                        ref={elmref}
                        className={
                                    (elmActive||elmFocus)?
                                    "element-active "+clsString:
                                    clsString
                                }
                    >
                    </path>
                </>
            }
            {element.tag=="h1"&&
                <h1 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                            }
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                        path={path}
                                        indesign={indesign}
                                    >
                                    </ElementContainer>
                                )
                                
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </h1>
            }
            {element.tag=="h2"&&
                <h2 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                            }
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return (
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                        path={path}
                                        indesign={indesign}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </h2>
            }
            {element.tag=="h3"&&
                <h3 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                            }
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                        path={path}
                                        indesign={indesign}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </h3>
            }
            {element.tag=="h4"&&
                <h4 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                            }
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(
                                    <ElementContainer
                                        elmkey={elmkey}                                    
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                        path={path} 
                                        indesign={indesign}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </h4>
            }
            {element.tag=="h5"&&
                <h5 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                            }
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                        path={path}
                                        indesign={indesign}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </h5>
            }
            {element.tag=="h6"&&
                <h6 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                            }
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(
                                    <ElementContainer
                                        elmkey={elmkey}
                                        element={elm}
                                        indexarr={[...indexarr, index]}
                                        position = {[...position, index]}
                                        type={type}
                                        parentkey={parentkey}
                                        parentindexarr={parentindexarr}
                                        parentposition={parentposition}
                                        path={path}
                                        indesign={indesign}
                                    >
                                    </ElementContainer>
                                )
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </h6>
            }
            {element.tag=="ol"&&
                <ol 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                            }
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(
                                <ElementContainer
                                    elmkey={elmkey}
                                    element={elm}
                                    indexarr={[...indexarr, index]}
                                    position = {[...position, index]}
                                    type={type}
                                    parentkey={parentkey}
                                    parentindexarr={parentindexarr}
                                    parentposition={parentposition}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>)
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </ol>
            }
            {element.tag=="ul"&&
                <ul 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                             }
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(<ElementContainer
                                            elmkey={elmkey}
                                            element={elm}
                                            indexarr={[...indexarr, index]}
                                            position = {[...position, index]}
                                            type={type}
                                            parentkey={parentkey}
                                            parentindexarr={parentindexarr}
                                            parentposition={parentposition}
                                            path={path}
                                            indesign={indesign}
                                        >
                                        </ElementContainer>)
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >

                                </ElementContainer>
                            )
                        })
                    }
                </ul>
            }
            {element.tag=="li"&&
                <li 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                "element-active "+clsString:
                                clsString
                            }
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(<ElementContainer
                                            elmkey={elmkey}
                                            element={elm}
                                            indexarr={[...indexarr, index]}
                                            position = {[...position, index]}
                                            type={type}
                                            parentkey={parentkey}
                                            parentindexarr={parentindexarr}
                                            parentposition={parentposition}
                                            path={path}
                                            indesign={indesign}
                                        >
                                        </ElementContainer>)
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </li>
            }
            {element.tag=="pre"&&
                <pre 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                clsString+" element-active":
                                clsString
                            }
                >
                    {innerhtml}
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(<ElementContainer
                                            elmkey={elmkey}
                                            element={elm}
                                            indexarr={[...indexarr, index]}
                                            position = {[...position, index]}
                                            type={type}
                                            parentkey={parentkey}
                                            parentindexarr={parentindexarr}
                                            parentposition={parentposition}
                                            path={path}
                                            indesign={indesign}
                                        >
                                        </ElementContainer>)
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </pre>
            }
            {element.tag=="iframe"&&
                <iframe 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                                (elmActive||elmFocus)?
                                clsString+" element-active":
                                clsString
                            }
                >
                    {element.childs.length>0&&
                        <>
                            {element.childs.map((elm, index)=>{
                                return(
                                <ElementContainer
                                    elmkey={elmkey}
                                    element={elm}
                                    indexarr={[...indexarr, index]}
                                    position = {[...position, index]}
                                    type={type}
                                    parentkey={parentkey}
                                    parentindexarr={parentindexarr}
                                    parentposition={parentposition}
                                    path={path}  
                                    indesign={indesign} 
                                >
                                </ElementContainer>)
                            })}
                        </>
                    }
                    {element.childenabled&&childs!=undefined&&childs.length>0&&
                        childs.map((elm, index)=>{
                            return (
                                <ElementContainer
                                    elmkey={parentkey}
                                    element={elm}
                                    indexarr={[...parentindexarr, index]}
                                    position={[...parentposition, index]}
                                    type={type}
                                    path={path}
                                    indesign={indesign}
                                >
                                </ElementContainer>
                            )
                        })
                    }
                </iframe>
            }
            {element.tag=="icon"&&
                <span 
                    {...getInteractions()}
                    {...props}
                    {...getExtraProps()}
                    ref={elmref}
                    className={
                        (elmActive||elmFocus)?
                                "element-active "+"material-symbols-outlined "+clsString:
                                "material-symbols-outlined "+clsString
                        }
                >
                    {element.name}
                </span>
            }
            {element.tag=="router"&&
                <div className={(elmActive||elmFocus)?
                                    clsString+" element-active":
                                    clsString
                                }
                     ref={elmref}
                >
                    <RouterContainer
                        element={element}
                        type={type}
                        elmFocus={elmFocus}
                        elmActive={elmActive}
                        elmkey={elmkey}
                        position={position}
                        absolutepath={path}
                        indesign={indesign}
                    >
                    </RouterContainer>
                </div>
            }
            {element.tag=="video"&&
                <div
                    className={
                        (elmActive||elmFocus)&&
                        "element-active"
                    }
                    ref={elmref}
                >
                    <VideoElement
                        options={props}
                    >
                    </VideoElement>
                </div>
            }
            {element.tag=="br"&&
                <br/>
            }
        </>
    )
}


export default HtmlNativeElements;
import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectAppClasses, selectAppTemplates } from "../../Services/apptemplates/apptemplates.slice";
import { generateStyleValue } from "./utils";

const StyleSheet = ({

})=>{

    const [appStyles, setAppStyles] = useState({});

    const ref = useRef(null);

    const remotestyles = useSelector(selectAppClasses);

    const getSelector = (name, state)=>{
        if(state=="default"){
            return "."+name
        }else{
            return "."+name+":"+state
        }
    }

    const getRule = (style)=>{
        let rule = "";
        let selector = getSelector(style.name, style.state);
        rule = rule+selector+"{";
        let valid = false;
        let cssrule = "";
        for(let i=0; i< style.styles.length; i++){
            rule = rule+"";
            cssrule = cssrule+"";
            let stylename = style.styles[i].name;
            let value = generateStyleValue(style.styles[i]);
            if(style.styles[i].name!=""&&style.styles[i].name!=undefined&&value!=""&&value!=undefined){
                let stylerule = ""+stylename+": "+value+";"
                rule = rule+stylerule;
                cssrule = cssrule+stylerule;
                valid = true;
            }
        }
        if(valid){
            rule = rule+"}";
        }else{
            rule = ""
        }
        return {
                 selector: selector,
                 rule:rule,
                 cssrule: cssrule
                };
    }

    const findRuleIndex = (selector, rules)=>{
        let ruleindex = -1;
        for(let i=0; i< rules.length; i++){
            if(rules[i].selectorText==selector){
                ruleindex = i;
                break
            }
        }
        return ruleindex;
    
    }

    const findStyleIndex = (selector, styles)=>{
        let styleindex = -1;
        for(let i=0; i<styles.length; i++){
            let sselector = getSelector(styles[i].name, styles[i].state); 
            if(sselector==selector){
                styleindex = i;
                break;
            }
        }
        return styleindex;
    }

    const reconcileClassesV2 = (remotestyles)=>{
        let remotestylesCopy = {...remotestyles};
        let sheet = ref.current.sheet;
        let rulelist = sheet.cssRules;
        let templates = Object.keys(remotestylesCopy);
        
        //flatten the stylelist
        let stylerules = [];
        for(let i=0; i<templates.length; i++){
            let tstyles = remotestylesCopy[templates[i]];
            for(let j=0; j<tstyles.length; j++){
                stylerules.push(tstyles[j])
            }
        }

        for(let i=0; i<stylerules.length; i++){
            let {rule, selector, cssrule} = getRule(stylerules[i]);
            if(selector!==""){
                let clsindex = findRuleIndex(selector, rulelist);
                if(clsindex==-1){
                    if(rule!=""){
                        sheet.insertRule(rule);
                    }
                }else{
                    let csstext = rulelist[clsindex].cssText;
                    if(csstext!=cssrule){
                        sheet.deleteRule(clsindex);
                        if(rule!=""){
                            sheet.insertRule(rule)
                        }
                    }
                }
            }
        }
        
        sheet = ref.current.sheet;
        rulelist = sheet.cssRules;

        for(let i=0; i<rulelist.length; i++){
            let selector = rulelist[i].selectorText;
            let styleindex = findStyleIndex(selector, stylerules);
            if(styleindex==-1){
                sheet.deleteRule(i);
            }
        }

        
    }

    useEffect(()=>{
        if(remotestyles!=undefined){
            let remotestylesCopy = {...remotestyles};
            reconcileClassesV2(remotestylesCopy);
        }
        
    },[remotestyles])

    return (
        <style 
            ref = {ref}
            id="toiler-sheet"
        >
        </style>
    )
}

export default StyleSheet;
